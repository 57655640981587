@import '../node_modules/bootstrap/scss/bootstrap.scss';


$theme-bleu : #05abc4;
$theme-jaune: #f2a20c;
$theme-gris: #4F5D75; 

body {
  background-image: url("cssExxist.png");
  background-repeat: repeat-y;
  background-position: right top;
}

h3{
  color: $theme-gris  ;
}

.App {
  text-align: center;
}

.navbar-brand{
  margin-left: 7%;
}

.sentence{
  padding: 5px;
  font-size: 30%;
  color: $theme-gris;
}

.bloc-text{
  text-align: justify;
  margin-top: 30px;;
  color: $theme-gris;
}

.bloc-text p{
  padding: 10px 20px 10px 20px;
}

.programme .card{
  margin: 10px;
  color: $theme-gris;
  text-align: center;
}

.programme{
  margin-top: 30px;
}

.card-img-top{
  width: 150px;
  text-align: center;
}

.card-img-top img{

    width: 70px;
}

.ctabutton, .donbutton{
  background-color: $theme-bleu; 
  border: none;
  color: white;
  padding: 10px 22px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0 10px 0;
  cursor: pointer; 
}


.donbutton{
  background-color: $theme-jaune; 
}

.linkspec{
  color: $theme-bleu;
}

.linkspeco{
  color: $theme-jaune; 
}

.card-container{
  margin: 10px;
}

.card-subject{
  margin-bottom: 10px;
}

.card-subject img{
    width: 230px;
    margin: 10px;
}

.back-home{
  margin: 20px;
}

.back-home-link{
  color: $theme-bleu;
}